if ('define' in window) {
define("discourse/theme-11/discourse/pre-initializers/theme-11-translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "theme-11-translations",
    initialize() {
      /* Translation data for theme 11 (en)*/
      const data = {
        "en": {
          "search_banner": {
            "headline": "Welcome to CNVC Global Home!",
            "subhead": "Conversations for the Global NVC Community",
            "search_button_text": ""
          }
        },
        "en_GB": {
          "search_banner": {
            "headline": "Welcome to your Global Home",
            "subhead": "A thriving community learning, sharing and applying Nonviolent Communication"
          }
        }
      };
      for (let lang in data) {
        let cursor = I18n.translations;
        for (let key of [lang, "js", "theme_translations"]) {
          cursor = cursor[key] = cursor[key] || {};
        }
        cursor[11] = data[lang];
      }
    }
  };
});
}
